.title {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 2rem;
    color: white;
}

.card {
    background-color: #0B5394;
}

.button {
    background-color: #F1C232;
    color: #ffffff;
}