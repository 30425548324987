.styled_table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled_table thead tr {
  background-color: #b4c6e7;
  color: #000;
  text-align: center;
  font-weight: bold;
}

.styled_table th,
.styled_table td {
  padding: 12px 15px;
}

.styled_table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.primary {
  background-color: #b4c6e7;
  color: #000;
  font-weight: bold;
}

.secondary {
  background-color: #767171;
}
